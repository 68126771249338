import { DateTime } from 'luxon'
import {
  channelApiBuilder,
  messageApiBuilder,
  messageActivityBuilder
} from './api'

/*
Build the channel API
*/
let _api = null

const channelApi = tenantId => {
  if (!_api) {
    _api = channelApiBuilder.build(tenantId)
  }
  return _api
}

export default {
  namespaced: true,
  state: {
    channels: [],
    connectionState: 'Disconnected',
    isConnected: false,
    unreadMessages: []
  },
  mutations: {
    addChannel(state, payload) {
      const existing = state.channels.find(c => c.id === payload.channel.id)
      if (!existing) {
        state.channels.push(payload.channel)
      }
    },
    removeChannel(state, payload) {
      state.channels = state.channels.filter(
        channel => channel.id !== payload.channel.id
      )
    },
    setChannels(state, payload) {
      state.channels = payload
    },
    setUnread(state, payload) {
      state.unreadMessages = payload || []
    },
    setConnectionState(state, payload) {
      state.connectionState = payload
    },
    setLastMessage() {}
  },
  actions: {
    async init({ commit }, payload) {
      const activityApi = await messageActivityBuilder.build(payload.tenantId)

      const unread = await activityApi.getUnreadMessages({
        latestCreateTime: DateTime.local()
          .minus({ days: 14 })
          .startOf('day')
          .toUTC()
          .toISO()
      })
      commit('setUnread', unread.data.results)

      const response = await channelApi(payload.tenantId).search({
        entityTypes: ['collection', 'material', 'person']
      })
      commit('setChannels', response.data.results)
    },
    async addChannel({ commit }, payload) {
      const response = await channelApi(payload.tenantId).post(payload)
      commit('addChannel', { channel: response.data })
    },
    addCreatedChannel({ commit }, payload) {
      commit('addChannel', { channel: payload })
    },
    async getChannels({ commit }, payload) {
      console.log('getChannels', payload)
      const response = await channelApi(payload.tenantId).search({
        entityTypes: ['collection', 'material', 'person']
      })
      commit('setChannels', response.data.results)
    },
    async sendMessage({ commit }, payload) {
      const api = messageApiBuilder.build(payload.tenantId, payload.channelId)
      const message = await api.post(payload.message)
      commit('setLastMessage', message.data)
      return message.data
    },
    async setConnectionState({ commit }, payload) {
      commit('setConnectionState', payload)
    },
    async markMessageAsRead({ commit }, payload) {
      const activityApi = await messageActivityBuilder.build(payload.tenantId)
      await activityApi.markMessageAsRead(payload.messageId)

      const unread = await activityApi.getUnreadMessages({
        latestCreateTime: DateTime.local()
          .minus({ days: 14 })
          .startOf('day')
          .toUTC()
          .toISO()
      })
      commit('setUnread', unread.data.results)
    },
    async markAsRead({ commit }, payload) {
      const activityApi = await messageActivityBuilder.build(payload.tenantId)
      await activityApi.markAsRead({
        markAllAsRead: payload.markAllAsRead,
        messageIds: payload.messageIds,
        channelIds: payload.channelIds
      })

      const unread = await activityApi.getUnreadMessages({
        latestCreateTime: DateTime.local()
          .minus({ days: 14 })
          .startOf('day')
          .toUTC()
          .toISO()
      })
      commit('setUnread', unread.data.results)
    },
    async updateUnreadMessages({ commit }, payload) {
      const activityApi = await messageActivityBuilder.build(payload.tenantId)

      const unread = await activityApi.getUnreadMessages({
        latestCreateTime: DateTime.local()
          .minus({ days: 14 })
          .startOf('day')
          .toUTC()
          .toISO()
      })
      commit('setUnread', unread.data.results)
    }
  },
  getters: {
    channels: state => {
      return state.channels
    },
    channelByEntityId: state => id => {
      return state.channels.find(c => c.entityId === id)
    },
    connectionState: state => {
      return state.connectionState
    },
    isConnected: state => {
      return state.connectionState === 'Connected'
    },
    unreadMessageCount: state => {
      return state.unreadMessages.length
    },
    unreadMessages: state => {
      return state.unreadMessages
    },
    unreadMessagesByEntity: state => id => {
      return state.unreadMessages.filter(m => m.entityId === id)
    }
  }
}
