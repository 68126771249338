import apiClient from '@/services/api/clients/api-client.js'
import configService from '@/services/config.service'

const path = `${configService.config.apiUrl}/formtypes`

const client = apiClient.build(path)

client.lookups = async function () {
  const response = await this.get();
  return response.data;

}

export default client
