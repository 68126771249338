import apiClient from '@/services/api/clients/api-client'
import configService from '@/services/config.service'

const path = `${configService.config.apiUrl}/collections/search`

const client = apiClient.build(path)

const search = options => {
  return client.get(null, { params: options })
}

const all = options => {
  const url = `${path}/all`
  return client.get(url, { params: options })
}

const bounds = query => {
  const url = `${path}/bounds`
  return client.__api.post(url, query)
}

export default {
  all,
  bounds,
  search
}
