<template>
  <div>
    <v-card flat>
      <v-card-text>
        <div class="d-flex justify-space-between mt-2">
          <div>
            <h3>{{  $t('twoFactorManage.resetHeader') }}</h3>
            {{ $t('twoFactorManage.resetInstructions') }}
          </div>
          <v-btn
            outlined
            color="red"
            @click="reset"
          >
            {{ $t('twoFactorManage.resetButton') }}
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            text
            color="default"
            @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AccountApiClient from '@/services/api/identity/account'
export default {
  data() {
    return {
    }
  },
  methods: {
    ...mapActions('user', ['disable2fa']),
    cancel() {
      this.$emit('close')
    },
    async reset() {
      try{
        await AccountApiClient.twoFactor.disable();
        await this.disable2fa()
        this.$emit('reset')
      }
      catch(e){
        alert(e.detail)
        this.error = e;
      }

    },
    disable() {
      this.$emit('disabled')
    }
  }
}
</script>
