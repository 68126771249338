<template>
  <v-menu
    left
    bottom
    offest-y
    :close-on-content-click="false"
    v-model="showMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn plain v-bind="attrs" v-on="on">
        <v-badge
          :content="unreadMessageCount"
          :value="unreadMessageCount"
          color="red"
          overlap
        >
          <v-icon>mdi-bell-outline</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card v-if="unreadMessageCount === 0" min-width="450">
      <v-card-title>
        {{ $t('unreadMessages') }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="text-center">{{
        $t('noUnreadMessages')
      }}</v-card-text>
    </v-card>
    <v-card v-if="unreadMessageCount > 0" min-width="450">
      <v-card-title>{{ $t('unreadMessages') }}</v-card-title>
      <v-divider></v-divider>
      <div class="text-right">
        <v-btn text class="text-caption" @click="markAllAsRead">{{
          $t('markAllAsRead')
        }}</v-btn>
      </div>

      <v-list nav dense style="max-height: 700px" class="overflow-y-auto">
        <v-list-item-group color="primary" v-model="selectedMessage">
          <v-list-item
            v-for="(message, i) in sortedUnreadMessages"
            :key="i"
            two-line
            @click="viewMessage(message)"
          >
            <unread-message :message="message" />
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UnreadMessage from './unread-message.vue'
import { entityLookupApi } from '@/messaging/api'

export default {
  components: {
    UnreadMessage
  },
  props: {
    tenantId: {
      type: String,
      required: false
    },
    user: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      innerTenantId: null,
      showMenu: false,
      selectedMessage: null,
      selectedItem: 1,
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' }
      ]
    }
  },
  computed: {
    ...mapGetters('messaging', ['unreadMessageCount', 'unreadMessages']),
    sortedUnreadMessages() {
      return [...this.unreadMessages].sort(
        (a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime)
      )
    }
  },
  watch: {
    tenantId(val) {
      if (!val) {
        this.stop()
      } else {
        this.start()
      }
    }
  },
  methods: {
    ...mapActions('messaging', [
      'markAsRead',
      'markMessageAsRead',
      'markAsRead'
    ]),
    async start() {},
    async markAllAsRead() {
      await this.markAsRead({ tenantId: this.tenantId, markAllAsRead: true })
      this.showMenu = false
    },
    async viewMessage(message) {
      this.selectedMessage = message

      const entityLookup = await entityLookupApi.getByShortId(
        message.channelName
      )

      switch (entityLookup.entityType) {
        case 'collection':
          this.$router.push({
            name: 'collection-detail',
            params: { collectionId: entityLookup.collectionId },
            query: { showMessages: true }
          })
          break
        case 'material':
          this.$router.push({
            name: 'material-detail',
            params: {
              collectionId: entityLookup.collectionId,
              materialId: entityLookup.materialId
            },
            query: { showMessages: true }
          })
          break
        case 'person':
          this.$router.push({
            name: 'collection-person-detail',
            params: {
              collectionId: entityLookup.collectionId,
              personId: entityLookup.collectionPersonId
            },
            query: { showMessages: true }
          })
          break
      }

      this.showMenu = false
    }
  }
}
</script>
