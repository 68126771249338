import apiClient from '@/services/api/clients/api-client.js'
import configService from '@/services/config.service'

const basePath = `${configService.config.apiUrl}/messaging/`

const build = function(tenantId) {
  const path = `${basePath}${tenantId}/activity`
  const client = apiClient.build(path)

  client.getUnreadMessages = options => {
    const url = `${path}/messages/unread`
    return client.get(url, { params: options })
  }

  client.markMessageAsRead = messageId => {
    const url = `${path}/messages/${messageId}/read`
    return client.__api.post(url)
  }

  client.markAsRead = model => {
    const url = `${path}/messages/read`
    return client.__api.post(url, model)
  }

  return client
}

export default {
  build
}
