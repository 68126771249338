import ApplicationConfiguration from '@/services/config.service'
import { themes } from '@/themes'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    theme: _.cloneDeep(themes.default)
  },
  mutations: {
    setTheme(state, payload) {
      state.theme = payload.theme
    }
  },
  actions: {
    setTheme({ commit }, themeName) {
      const defaultName = ApplicationConfiguration.get('theme')
      const name = themeName || defaultName

      console.log('Setting theme', name)

      if (themes[name]) {
        commit('setTheme', { theme: _.cloneDeep(themes[name]) })
      }
    }
  },
  getters: {
    theme: state => {
      return state.theme
    },
    location: state => {
      return state.theme.location
    }
  }
}
