import apiClient from '@/services/api/clients/api-client.js'
import configService from '@/services/config.service'

const basePath = `${configService.config.apiUrl}/messaging/`

const build = function(tenantId, channelId) {
  const path = `${basePath}${tenantId}/channel/${channelId}/message`
  return apiClient.build(path)
}

export default {
  build
}
