<template>
  <v-hover v-slot="{ hover }" close-delay="200">
    <v-card
      :elevation="hover ? 8 : 2"
      :class="{ 'on-hover': hover }"
      class="mx-auto"
      :height="height"
      :width="width"
      @click="onClick"
    >
      <img :src="src" />
    </v-card>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex'
import locationService from '@/services/location'

export default {
  props: {
    label: { default: '' },
    mgrs: { default: null },
    lat: { default: null },
    long: { default: null },
    width: { default: 600 },
    height: { default: 300 },
    zoom: { default: locationService.zoom }
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters('app', ['config']),
    apiKey(){
      return this.config.googleMapsApiKey
    },
    mapLocation() {
      if (this.mgrs) {
        const p = locationService.getLatLng(this.mgrs)
        return `${p.latitude},${p.longitude}`
      }

      return `${this.lat},${this.long}`
    },
    size() {
      return `${this.width}x${this.height}`
    },
    markers() {
      return `size:tiny|color:red|${this.mapLocation}`
    },
    src() {
      return `https://maps.googleapis.com/maps/api/staticmap?center=${this.mapLocation}&size=${this.size}&zoom=${this.zoom}&markers=${this.markers}&key=${this.apiKey}`
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style>
.on-hover {
  cursor: pointer;
}
</style>
