import apiClient from '@/services/api/clients/api-client.js'
import configService from '@/services/config.service'

const basePath = `${configService.config.apiUrl}/collection`

const build = function(collectionId) {
  const path = `${basePath}/${collectionId}/forms`

  const client = apiClient.build(path)

  client.complete = (id, model) => {
    const completePath = `${client.__api.entityPath(path, id)}/complete`
    return client.__api.put(completePath, model).then(response => response.data)
  }

  client.archive = id => {
    const completePath = `${client.__api.entityPath(path, id)}/complete`
    return client.__api.put(completePath).then(response => response.data)
  }
  return client;
}

export default {
  build
}
