import apiClient from '@/services/api/clients/api-client.js'
import configService from '@/services/config.service'

const path = `${configService.config.identityServerUrl}/api/account`

// const client = apiClient.build(path)

// client.search = options => {
//   const searchPath = `${path}/search`
//   return client.__api
//     .get(searchPath, { params: options })
//     .then(response => response.data)
// }

// client.all = options => {
//   const url = `${path}`
//   return client.get(url, { params: options }).then(response => response.data)
// }

// client.deleteConfirm = id => {
//   return client.delete(id, 'confirm').then(response => response.data)
// }

// client.setKeyImage = (id, mediaId) => {
//   const url = `${path}/${id}/keyImage`
//   const payload = {
//     MediaItemId: mediaId
//   }
//   return client.__api.put(url, payload).then(response => response.data)
// }

const client = {
  changePassword: (payload) => {
    return apiClient.post(`${path}/password`, payload)
  },
  reset: () => {
    return apiClient.post(`${path}/password/reset`)
  },
  twoFactor: {
    get: () => {
      return apiClient.get(`${path}/2fa`)
    },
    enable: (payload) => {
      return apiClient.post(`${path}/2fa`, payload)
    },
    disable: () => {
      return apiClient.delete(`${path}/2fa`)
    },
    update: (payload) => {
      return apiClient.put(`${path}/2fa`, payload)
    }
  }
}

export default client
