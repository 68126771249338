import Home from '@/views/collection/index'
import Secure from '@/views/home/secure'
import Logout from '@/views/home/logout'
import SelectOrg from '@/views/home/select-org'

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/secure',
    name: 'Secure',
    component: Secure,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/select-org',
    name: 'select-org',
    component: SelectOrg,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/browse/:shortId',
    name: 'browse',
    redirect: to => {
      const shortId = to.params.shortId
      if (shortId.startsWith('C')) {
        return { name: 'collections-index', query: { term: to.params.shortId } }
      }

      if (shortId.startsWith('P')) {
        return { name: 'people-index', query: { term: to.params.shortId } }
      }

      return '/'
    }
  }
]
