import ApplicationConfiguration from '@/services/config.service'

export default {
  namespaced: true,
  state: {
    loading: false,
    config: {},
    error: null
  },
  mutations: {
    setLoading(state, payload){
      state.loading = payload
      state.error = null
    },
    setConfig(state, payload){
      state.config = payload
    },
    setError(state, paylod){
      state.error = paylod
    }
  },
  actions:{
    async initializeApp({ commit, dispatch }){
      try{
        commit('setLoading', true)

        const config = await ApplicationConfiguration.load()
        commit('setConfig', config)

        await dispatch('theme/setTheme', null, { root: true })
        await dispatch('user/setUser', null, { root: true })

        commit('setLoading', false)
      }
      catch(error){
        commit('setError', error)
      }
    }
  },
  getters: {
    loading: state => {
      return state.loading
    },
    error: state => {
      return state.error
    },
    config: state => {
      return state.config
    },
    apiUrl: state => {
      return state.config.apiUrl
    }
  }
}
