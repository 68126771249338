<template>
<v-card flat>
  <v-card-title>{{ $t('twoFactorTitle') }}</v-card-title>
  <v-card-actions v-if="!showForm">
    {{ message  }}
    <v-spacer></v-spacer>
    <v-btn
      v-if="!is2faEnabled"
      @click="setup2fa"
      color="default"
    >
      {{ $t('twoFactorSetupButton') }}
    </v-btn>

    <v-btn
      v-if="is2faEnabled"
      @click="showManage = true"
      color="default"
    >
      {{ $t('twoFactorManageButton') }}
    </v-btn>
  </v-card-actions>

  <two-factor-setup
    v-if="showSetup"
    @close="showSetup = false"/>

  <manage-two-factor
    v-if="showManage"
    @close="showManage = false"
    @reset="onReset"
    @disabled="onDisabled"/>

</v-card>
</template>

<script>
import { mapGetters } from 'vuex'
//import AccountApiClient from '@/services/api/identity/account'
//import AlertDialog from '@/components/dialogs/alert'
import TwoFactorSetup from './setup'
import ManageTwoFactor from './manage'

export default {
  components: {
    //AlertDialog,
    ManageTwoFactor,
    TwoFactorSetup
  },
  data() {
    return {
      showManage: false,
      showSetup: false,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    is2faEnabled() {
      return this.user.isTwoFactorEnabled
    },
    message() {
      return this.is2faEnabled ? this.$t('twoFactorIsEnabled') : this.$t('twoFactorIsNotEnabled')
    },
    showForm(){
      return this.showSetup || this.showManage
    }
  },
  methods: {
    setup2fa() {
      this.showSetup = true
    },
    onDisabled(){
      alert('disabled')
      this.showManage = false
    },
    onReset() {
      this.showManage = false
      //todo confirm
      //todo update user object
    }
  }
}
</script>
