import apiClient from '@/services/api/clients/api-client.js'
import configService from '@/services/config.service'

const basePath = `${configService.config.apiUrl}/collection`

const build = function(collectionId) {
  const path = `${basePath}/${collectionId}/material`
  return apiClient.build(path)
}

export default {
  build
}
