<template>
  <div class="text-center">
    <h2>{{ $t('logoutComplete') }}</h2>
    <div class="ma-4">
      <v-btn link x-large @click="login" color="primary">
        {{ $t('login') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AuthService from '@/services/auth.service'

export default {
  methods: {
    ...mapActions('user', ['clearUser']),
    login() {
      AuthService.login()
    }
  },
  async mounted(){
    await this.clearUser()
  }
}
</script>

<style></style>
