<template>
<v-card  class="mx-auto" max-width="600">
  <v-card-title>{{ $t('changeOrganization') }}</v-card-title>
  <v-card-text>
    <v-data-table
      :headers="headers"
      :items="sortedTenants"
      :items-per-page="-1"
      hide-default-footer
      class="elevation-1"
    >

    <template v-slot:item="{ item }">
      <tr :class="item.id == tenant.id ? 'blue-grey lighten-5': ''">
        <td>{{ item.name }}</td>
        <td>{{ item.roles.join() }}</td>
        <td class="text-right">
          <v-btn v-if="item.id != tenant.id" @click="onSelect(item)" color="primary" small class="text-right">{{ $t('change') }}</v-btn>
        </td>
      </tr>
    </template>
    </v-data-table>
  </v-card-text>
</v-card>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default{
  data(){
    return {
      sortedTenants: [],
      headers: [
        { text: 'Organization', value: 'name' },
        { text: 'Roles', value: 'roles' },
        { text: '', value: 'actions', align: 'right' }
      ]
    }
  },
  computed: {
    ...mapGetters('user', ['user', 'tenant']),
  },
  mounted(){
    this.sortedTenants = this.user.memberships.sort((a, b) => a.name.localeCompare(b.name))
    this.selectedItem = this.tenant.id
  },
  methods:{
    ...mapActions('user', ['setTenant']),
    async onSelect(item){
      const t = this.user.memberships.find(m => m.id == item.id)
      console.log('tenant selected', {t, item})

      await this.setTenant(t)
      this.$router.push('/')
    }
  }
}
</script>
