<template>
  <div>
    <h1>{{ $t("profile") }}</h1>


    <v-card flat class="pt-4">
      <v-tabs
        v-model="tab"
        background-color="tab-background"
      >
        <v-tab key="profile">{{  $t('profile') }}</v-tab>
        <v-tab key="organizations">{{ $t('organizations') }}</v-tab>
        <v-tab key="security">{{ $t('security') }}</v-tab>

        <v-tabs-items v-model="tab" class="mt-2">

          <v-tab-item key="profile">
            <table class="user-table">
              <tr>
              <th>{{$t('name')}}:</th>
              <td>{{ user.name | wordify }}</td>
            </tr>
            <tr>
              <th>Email:</th>
              <td>{{ user.email }}</td>
            </tr>
            <tr>
              <th>User Code:</th>
              <td>{{ user.userCode }}</td>
            </tr>
          </table>
          </v-tab-item>

          <v-tab-item key="organizations">
            <v-data-table
              :headers="headers"
              :items="user.memberships"
              :items-per-page="-1"
              class="elevation-1">
            </v-data-table>
          </v-tab-item>
          <v-tab-item key="security">
            <change-password/>
            <v-divider/>
            <two-factor/>
            <v-divider/>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChangePassword from '@/components/security/change-password'
import TwoFactor from '@/components/security/2fa'

export default {
  components: {
    ChangePassword,
    TwoFactor
  },
  data() {
    return {
      headers: [
        { text: 'Organizations', value: 'name' },
        { text: 'Roles', value: 'roles' },
        { text: '', value: 'actions' }
      ],
      tab: 'profile'
    }
  },
  computed: {
    ...mapGetters('user', ['user'])
  }
}
</script>

<style scoped="true">
  .user-table th{
    text-align: right;
    padding-right: 10px;
  }
</style>
