<template>
  <v-form ref="form" v-model="valid">
  <v-card v-if="!is2faEnabled">
    <v-card-text>
      <h3>{{  $t('twoFactorSetupTitle') }}</h3>
      <div >
        <div>
        <p>{{ $t('twoFactorSetup.intro') }}</p>
        <ol class="list">
        <li>
            <p>
                {{ $t('twoFactorSetup.step1') }}
                <a href="https://support.microsoft.com/en-us/account-billing/download-microsoft-authenticator-351498fc-850a-45da-b7b6-27e523b8702a">{{  $t('twoFactorSetup.apps.microsoft') }}</a>,
                <a href="https://www.authy.com/download/">{{  $t('twoFactorSetup.apps.authy') }}</a>,
                {{ $t('or')}} {{ $t('twoFactorSetup.apps.google') }}
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share">{{$t('android')}}</a> {{ $t('and')}}
                <a href="https://apps.apple.com/us/app/google-authenticator/id388497605">{{ $t('iOS')}}</a>.
            </p>
        </li>
        <li>
            <p>{{ $t('twoFactorSetup.step2')}}</p>
            <v-alert class="pa-2 text-large" color="blue-grey lighten-4">{{ formattedKey }}</v-alert>
            <qrcode :value="model.authenticatorUri" :size="200"></qrcode>
            <!-- <div id="qrCode"></div>
            <div id="qrCodeData" v-bind:data-url="model.authenticatorUri"></div> -->
        </li>
        <li>
            <p class="pt-4">
              {{ $t('twoFactorSetup.step3')}}
            </p>
            <v-alert v-if="error" type="error" color="red lighten-1">{{ error.detail }}</v-alert>
              <div class="ma-auto position-relative" style="max-width: 300px">
                <v-otp-input
                  v-model="code"
                  :rules="codeRules"
                  length="6"
                ></v-otp-input>
              </div>

        </li>
          </ol>
      </div>
      </div>
    </v-card-text>
    <v-card-actions >
    <v-spacer></v-spacer>
    <v-btn text @click="cancel" color="default">
      {{ $t('cancel') }}
    </v-btn>
    <v-btn
        @click="setup2fa"
        color="primary"
        :disabled="!valid"
      >
        {{ $t('twoFactorSetupButton') }}
      </v-btn>
  </v-card-actions>
  </v-card>

  <v-card v-if="is2faEnabled">
    <v-card-text>
      <h3>{{  $t('twoFactorSetup.introEnabled') }}</h3>
    </v-card-text>
    <v-card-actions >
    <v-spacer></v-spacer>
    <v-btn text @click="cancel" color="default">
      {{ $t('cancel') }}
    </v-btn>
    </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">{{ $t('twoFactorSetup.recoveryCodes')}}</v-card-title>
        <v-card-text>
          {{ $t('twoFactorSetup.recoveryCodesInstructions')}}
          <v-list dense>
            <v-list-item v-for="(code, index) in codes" :key="index">
              <v-list-item-content>{{ code }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="closeDialog">{{ $t('close') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="copyToClipboard">{{  $t('copyToClipboard') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AccountApiClient from '@/services/api/identity/account'
import Qrcode from 'qrcode.vue'


export default{
  components: {
    Qrcode
  },
  data(){
    return{
      code: '',
      codeRules: [
        v => !!v || this.$t('twoFactorSetup.codeRequired'),
        v => v.length >= 6 || this.$t('twoFactorSetup.codeMinLength')
      ],
      codes: [],
      dialog: false,
      error: null,
      model: {},
      valid: false,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    is2faEnabled() {
      return this.model.isTwoFactorEnabled
    },
    formattedKey(){
      if(this.model.sharedKey){
        return this.model.sharedKey.replace(/(.{4})(?=.)/g, '$1-')
      }
      return this.model.sharedKey
    }
  },
  mounted(){
    console.log('mounted 2fa setup');
    this.load()
  },
  methods: {
    ...mapActions('user', ['enable2fa']),
    async load(){
      const response = await AccountApiClient.twoFactor.get();
      this.model = response.data;
    },
    cancel() {
      this.$emit('close')
    },
    async setup2fa(){
      try{
        const response = await AccountApiClient.twoFactor.enable({
          code: this.code
        });

        await this.enable2fa()

        this.code = null;
        this.codes = response.data.codes;
        this.dialog = true;
      }
      catch(e){
        this.error = e;
      }
    },
    copyToClipboard() {
      const codesText = this.codes.join('\n');
      navigator.clipboard.writeText(codesText).then(
        () => {
          this.$emit('close')
        },
        (err) => {
          console.error('Failed to copy: ', err);
        }
      );
    },
    closeDialog(){
      this.dialog = false;
      this.$emit('close')
    }
  }
}
</script>
