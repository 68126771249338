import apiClient from '@/services/api/clients/api-client.js'
import mediaApi from '@/services/api/media'
import configService from '@/services/config.service'

const basePath = `${configService.config.apiUrl}/collection`

const build = function(collectionId) {
  const path = `${basePath}/${collectionId}/drawings`

  const client = apiClient.build(path)

  client.uploadImage = function(drawingId, dataUri) {
    const uploadPath = `${path}/${drawingId}/image`
    const file = mediaApi.dataURItoBlob(dataUri)

    const formData = new FormData()
    formData.append('files', file)

    return client.__api
      .post(uploadPath, formData)
      .then(response => response.data)
  }

  return client
}

export default {
  build
}
