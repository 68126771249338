import _ from 'lodash'
import store from '@/store'
import i18n from '@/plugins/i18n'
import { contentWithBorder, field, safeText } from '@/services/pdf-utils'
import locationService from '@/services/location'
import { current, format } from '@/services/datetime'
import collectionTemplate from './components/collection'
import { coverPage, mediaTable } from './components'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

/*
 * Get PDF as a buffer
 */
export const exportCollectionToBuffer = async (collection, options) => {
  const pdf = await exportCollection(collection, options)
  return pdf.getBuffer()
}

/*
 * Download pdf
 */
export const exportCollectionAndDownload = async (collection, options) => {
  const pdf = await exportCollection(collection, options)

  const filename =
    options.filename || `${collection.shortId}_${collection.name}.pdf`

  pdf.download(filename)
}

/*
 * Open pdf
 */
export const exportCollectionAndOpen = async (collection, options) => {
  const pdf = await exportCollection(collection, options)
  pdf.open()
}

/*
 * Export a collection to PDF using pdfmake.
 * @param {Object} collection - The collection to export. Should be fully hydrated.
 */
export const exportCollection = async (collection, options) => {
  const context = {
    progress: options.onProgress || (() => {}),
    complete: options.onComplete || (() => {})
  }

  const doc = await exportCollectionDocumentDefinition(
    collection,
    options,
    context
  )

  context.progress(i18n.t('export.pdf.progress.creating'))
  const pdf = pdfMake.createPdf(doc)

  context.complete(i18n.t('export.pdf.progress.complete'))

  return pdf
}

/*
 * Export collection document definition
 */
export const exportCollectionDocumentDefinition = async (
  collection,
  options,
  context
) => {
  context.progress(i18n.t('export.pdf.progress.starting'))

  collection = await validateCollection(collection)
  return await buildDocumentDefinition(collection, options, context)
}

/*
 * validate the collection and set default values as necesary
 */
const validateCollection = async collection => {
  // location
  if (collection.mgrs && (!collection.latitude || !collection.longitude)) {
    const latLng = await locationService.getLatLng(collection.mgrs)
    collection.latitude = latLng.latitude.toPrecision(8)
    collection.longitude = latLng.longitude.toPrecision(8)
  }

  if (!collection.mgrs && collection.latitude && collection.longitude) {
    collection.mgrs = await locationService.getMgrs(collection)
  }

  //collections
  collection.media = collection.media || []
  collection.material = collection.material || []
  collection.people = collection.people || []

  return collection
}

/*
 * Build the document definition for a collection
 */
export const buildDocumentDefinition = async (collection, options, context) => {
  context.progress(i18n.t('export.pdf.progress.building'))

  const doc = _.cloneDeep(collectionTemplate)
  const now = current.now()
  const theme = store.getters['theme/theme']
  const user = store.getters['user/user']
  const organization = store.getters['user/organization']

  //
  // Shared images Images
  //
  doc.images['appLogo'] = theme.logoDataUrl

  //
  // Info
  //
  doc.info = {
    title: collection.name,
    creator: theme.name,
    producer: theme.name
  }
  context.progress(i18n.t('export.pdf.progress.info'))

  // Cover page
  context.progress(i18n.t('export.pdf.progress.coverPage'))
  await coverPage(doc, {
    name: collection.name,
    title: `${theme.name.toUpperCase()} ${i18n.t('collection')} ${i18n.t(
      'report'
    )}`,
    organization: organization.name,
    preparedBy: user.name,
    logo: theme.logoDataUrl
  })

  // header & footer functions
  doc.header = currentPage => {
    if (currentPage < 3) {
      return {}
    }

    return {
      text: [
        `${i18n.t('collection')}: `,
        { text: safeText(collection.shortId), bold: true }
      ],
      style: 'pageHeader',
      alignment: 'right'
    }
  }

  doc.footer = (currentPage, pageCount) => {
    const left =
      currentPage > 1
        ? {
          stack: [{ image: 'appLogo', width: 25, alignment: 'left' }],
          margin: [5, 0, 0, 150]
        }
        : { text: '' }

    return {
      columns: [
        left,
        { text: `${currentPage.toString()} / ${pageCount}` },
        { text: `${format.toLocal(now)}`, alignment: 'right' }
      ],
      style: 'pageFooter',
      alignment: 'center'
    }
  }
  context.progress(i18n.t('export.pdf.progress.headerFooter'))

  await collectionDetails(doc, collection, options, context)

  context.progress(i18n.t('export.pdf.progress.documentDefinitionComplete'))

  console.log('doc and template', { doc, collectionTemplate })
  return doc
}

/*
 * Build the collection details section
 */
const collectionDetails = async (doc, collection, options, context) => {
  context.progress(i18n.t('export.pdf.progress.collectionDetails'))

  // title
  doc.content.push({
    text: [
      { text: `${i18n.t('collection')}: `, bold: false },
      { text: safeText(collection.shortId), bold: true }
    ],
    style: 'header'
  })
  doc.content.push({
    text: safeText(i18n.t('summary').toUpperCase()),
    alignment: 'center',
    style: 'caption',
    margin: [0, 0, 0, 10]
  })

  const row = {
    columns: [],
    margin: [0, 10, 0, 15]
  }

  //location
  const locationColumn = {
    width: '50%',
    stack: []
  }

  const imageUrl = await locationService.getGoogleMapsImageAsDataUrl(
    collection,
    10
  )
  doc.images['map'] = imageUrl

  locationColumn.stack.push(
    contentWithBorder({
      image: 'map',
      width: 255,
      alignment: 'center'
    })
  )

  locationColumn.stack.push({
    columns: [
      {
        width: 'auto',
        stack: [{ text: 'Lat/Lng:' }, { text: 'MGRS:' }],
        style: 'fieldLabel',
        fontSize: 10
      },
      {
        width: '*',
        stack: [
          { text: `${collection.latitude}, ${collection.longitude}` },
          { text: safeText(collection.mgrs) }
        ],
        style: 'fieldValue'
      }
    ]
  })

  row.columns.push(locationColumn)

  //details
  row.columns.push({
    width: '50%',
    stack: [
      field(i18n.t('eventDateTime'), format.toLocal(collection.eventDateTime)),
      field(i18n.t('name'), collection.name),
      field(i18n.t('collectionNumber'), collection.collectionNumber),
      { text: '', margin: [0, 0, 0, 50] }
    ],
    style: 'fieldValue',
    margin: [10, 0]
  })

  if (collection.people.length > 0) {
    row.columns[1].stack.push({
      text: [{ text: `${i18n.t('people')}: ` }, collection.people.length]
    })
  }

  if (collection.people.length > 0) {
    row.columns[1].stack.push({
      text: [{ text: `${i18n.t('material')}: ` }, collection.material.length]
    })
  }

  doc.content.push(row)

  // description
  doc.content.push({
    stack: [
      { text: i18n.t('description'), style: 'fieldLabel' },
      { text: safeText(collection.description) }
    ],
    style: 'body',
    pageBreak: collection.media.length > 0 ? 'after' : null
  })

  //
  // Attachments
  doc.content.push(await mediaTable(doc, collection.media))

  //
  // Material
  await buildMaterialPages(doc, collection, options, context)

  //
  // People
  await buildPersonPages(doc, collection, options, context)

  return doc
}

/*
 * Build material pages
 */
const buildMaterialPages = async (doc, collection, options, context) => {
  context.progress(i18n.t('export.pdf.progress.materialPages'))

  for (let i = 0; i < collection.material.length; i++) {
    const material = collection.material[i]
    context.progress(
      i18n.t('export.pdf.progress.materialPage', { material: material.shortId })
    )

    doc.content.push({
      text: [
        { text: `${i18n.t('material')}: `, bold: false },
        { text: safeText(material.shortId), bold: true }
      ],
      style: 'header',
      pageBreak: 'before'
    })

    doc.content.push({
      text: safeText(i18n.t('summary').toUpperCase()),
      alignment: 'center',
      style: 'caption',
      margin: [0, 0, 0, 10]
    })

    const page = {
      stack: [
        field(i18n.t('name'), material.name),
        field(i18n.t('description'), material.description)
      ]
    }
    doc.content.push(page)

    const table = await mediaTable(doc, material.media || [])

    if (table) {
      doc.content.push(table)
    }
  }
}

/*
 * Build person pages
 */
const buildPersonPages = async (doc, collection, options, context) => {
  context.progress(i18n.t('export.pdf.progress.personPages'))

  for (let i = 0; i < collection.people.length; i++) {
    const person = collection.people[i]
    context.progress(
      i18n.t('export.pdf.progress.personPages', { person: person.shortId })
    )

    doc.content.push({
      text: [
        { text: `${i18n.t('person')}: `, bold: false },
        { text: safeText(person.shortId), bold: true }
      ],
      style: 'header',
      pageBreak: 'before'
    })

    doc.content.push({
      text: safeText(i18n.t('summary').toUpperCase()),
      alignment: 'center',
      style: 'caption',
      margin: [0, 0, 0, 10]
    })

    const page = {
      stack: [
        field(i18n.t('name'), person.name),
        field(i18n.t('description'), person.description)
      ],
      style: 'subheader'
    }
    doc.content.push(page)

    const table = await mediaTable(doc, person.media || [])

    if (table) {
      doc.content.push(table)
    }
  }
}
