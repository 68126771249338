<template>
<v-card flat>
  <v-card-title>{{ $t('password') }}</v-card-title>

  <v-card-actions v-if="!showForm">
    {{ $t('passwordChangeMessage') }}
    <v-spacer></v-spacer>
    <v-btn
      @click="toggleForm"
      color="default"
    >
      {{ $t('passwordChangeButton') }}
    </v-btn>
  </v-card-actions>

  <v-card-text v-if="showForm">
    <v-alert type="error" v-if="error">{{ error.message }}</v-alert>
    <div>{{ $t('passwordRequirementsMessage') }}</div>
    <v-form>
      <v-text-field
        v-model="currentPassword"
        :rules="currentPasswordRules"
        :label="currentPasswordLabel"
        type="password"
        required
      ></v-text-field>
      <v-text-field
        v-model="newPassword"
        :rules="newPasswordRules"
        :label="newPasswordLabel"
        type="password"
        required
      ></v-text-field>
      <v-text-field
        v-model="confirmPassword"
        :rules="confirmPasswordRules"
        :label="confirmPasswordLabel"
        type="password"
        required
      ></v-text-field>


    </v-form>
  </v-card-text>
  <v-card-actions v-if="showForm">
    <v-btn text @click="reset" color="default">
      {{ $t('passwordResetButton') }}
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn text @click="cancel" color="default">
      {{ $t('cancel') }}
    </v-btn>
    <v-btn
        @click="changePassword"
        :disabled="!valid"
        color="primary"
      >
        {{ $t('changePassword') }}
      </v-btn>
  </v-card-actions>
  <alert-dialog
    :title="$t('passwordChangeSuccessTitle')"
    :text="$t('passwordChangeSuccessMessage')"
    v-model="showSuccess"/>
  <alert-dialog
    :title="$t('passwordResetSuccessTitle')"
    :text="$t('passwordResetSuccessMessage')"
    v-model="showResetConfirmation"/>
</v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import AccountApiClient from '@/services/api/identity/account'
import AlertDialog from '@/components/dialogs/alert'

export default {
  components: {
    AlertDialog
  },
  data() {
    return {
      error: null,
      confirmPassword: '',
      newPassword: '',
      currentPassword: '',
      showForm : false,
      showSuccess: false,
      showResetConfirmation: false,
      valid: true,

      currentPasswordLabel: this.$t('currentPassword'),
      newPasswordLabel: this.$t('newPassword'),
      confirmPasswordLabel: this.$t('confirmPassword'),

      currentPasswordRules: [
        v => !!v || this.$t('passwordRequiredError'),
        v => v === this.currentPassword || this.$t('passwordRequiredError'),
      ],
      newPasswordRules: [
        v => !!v || this.$t('passwordRequiredError'),
        v => v.length >= 8 || this.$t('passwordLengthError'),
      ],
      confirmPasswordRules: [
        v => !!v || this.$t('passwordRequiredError'),
        v => v === this.newPassword || this.$t('passwordMatchError'),
      ]
    }
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  methods: {
    changePassword() {

      this.error = null

      AccountApiClient.changePassword({
        currentPassword: this.currentPassword,
        newPassword: this.newPassword
      }).then(() => {

        this.clearForm()
        this.showSuccess = true
        this.showForm = false

      }).catch((error) => {
        this.error = error
      })
    },
    reset(){
      this.error = null

        AccountApiClient.reset()
          .then(() => {
          this.clearForm()
          this.showForm = false
          this.showResetConfirmation = true
        }).catch((error) => {
          this.error = error
        })
    },
    cancel(){
      this.showForm = false

      this.clearForm()
    },
    toggleForm() {
      this.showForm = !this.showForm
    },
    clearForm() {
      this.error = null
      this.showSuccess = false
      this.currentPassword = ''
      this.newPassword = ''
      this.confirmPassword = ''
    }
  }
}
</script>
