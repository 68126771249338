import Index from '@/views/profile/index'

export default [
  {
    path: '/profile',
    name: 'profile-index',
    component: Index,
    meta: {
      authenticated: true
    }
  }
]
