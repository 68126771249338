import axios from 'axios'
import apiClient from '@/services/api/clients/api-client.js'
import AuthService from '@/services/auth.service'
import store from '@/store'
import queue, {requestQueue} from './queue'
import cache from './cache'
import { fileUtils } from '@/services/file-utils.service'
import configService from '@/services/config.service'

const path = `${configService.config.apiUrl}/media/`

const client = apiClient.build(path)

client.__api.interceptors.request.use(async config => {
  config.signal = queue.signal
  return config
})

const getDownloadUrl = async (mediaId, size) => {
  const url = `${path}${mediaId}/downloadUrl?size=${size}`
  return client.__api.get(url).then(response => response.data)
}

const dataURItoBlob = dataURI => {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]

  const arrayBuffer = new ArrayBuffer(byteString.length)
  const _ia = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i)
  }

  const dataView = new DataView(arrayBuffer)
  const blob = new Blob([dataView], { type: mimeString })
  return blob
}

const upload = function(file, options) {
  const uploadPath = buildUploadPath(options)

  const formData = new FormData()
  formData.append('files', file)

  return client.__api.post(uploadPath, formData).then(response => response.data)
}

const uploadFormData = function(formData, options) {
  const uploadPath = buildUploadPath(options)

  return client.__api.post(uploadPath, formData).then(response => response.data)
}

const deleteItem = function(id, options) {
  let path = buildUploadPath(options)
  path = path.concat(`/${id}`)

  return client.__api.delete(path).then(response => response.data)
}

function buildUploadPath(options) {
  let uploadPath = `${configService.config.apiUrl}`
  if (options.collectionId) {
    uploadPath = uploadPath.concat(`/collection/${options.collectionId}`)
  }
  if (options.materialId) {
    uploadPath = uploadPath.concat(`/material/${options.materialId}`)
  }
  if (options.personId) {
    uploadPath = uploadPath.concat(`/person/${options.personId}`)
  }

  uploadPath = uploadPath.concat('/media')

  return uploadPath
}

/*------------------------------------------------------
* Retrieve binary version of a file
------------------------------------------------------*/
const imageClient = axios.create({
  baseURL: path,
  responseType: 'arraybuffer'
})

imageClient.interceptors.request.use(async config => {
  const accessToken = await AuthService.getAccessToken()

  if (!accessToken) {
    throw new Error('A valid access token is required to query images')
  }

  const tenantId = store.getters['user/tenantId']
  if(tenantId) {
    config.headers['X-TenantId'] = tenantId
  }

  config.headers.Authorization = `Bearer ${accessToken}`
  config.signal = queue.signal
  return config
})

const getBase64Url = async (mediaId, options, config) => {
  options = options || {}
  config = config || {}

  const cacheKey = `${path}${mediaId}${options.size? options.size : ''}`

  return requestQueue.enqueue(() => getBase64UrlAction(mediaId, options, config, cacheKey), cacheKey)
}

const getBase64UrlAction = async (mediaId, options, config, cacheKey) => {
  try {
    options = options || {}
    config = config || {}

    const mediaItemPath = `${path}${mediaId}`
    cacheKey = cacheKey || `${path}${mediaId}${options.size? options.size : ''}`

    if (!options.noCache) {
      const cachedValue = await cache.fetch(cacheKey)
      if (cachedValue) {
        return cachedValue
      }
    }

    options.noRedirects = true

    let response = await imageClient.get(mediaItemPath, {
      ...config,
      params: { ...options }
    })

    //Handle manual redirects
    if (response.status === 204 && response.headers['location']) {
      const location = response.headers['location']

      const redirectClient = axios.create({
        baseURL: location,
        responseType: 'arraybuffer'
      })

      response = await redirectClient.get(location, { ...config })
    }

    const contentType = response.headers['content-type']

    if (response.status === 200) {
      if (options.binary) {
        return response.data
      }

      const buffer = Buffer.from(response.data, 'binary')
      const base64 = buffer.toString('base64')
      const result = `data:${contentType};base64,${base64}`

      cache.put(cacheKey, result, contentType)
      return result
    }

    throw new Error(`Failed to load image, status: ${response.status}`)
  } catch (err) {
    if (axios.isCancel(err)) {
      return ''
    } else {
      throw err
    }
  }
}

/*------------------------------------------------------
* Multipart upload
------------------------------------------------------*/
const buildMultipartPath = options => `${buildUploadPath(options)}/multipart`

const raiseEvent = (event, onProgress) => {
  if (typeof onProgress == 'function') {
    onProgress({ parts: 0, uploaded: 0, ...event })
  }
}

const uploadMultipart = async (request, file, options) => {
  const uploadRequest = {
    ...request,
    fileName: file.name,
    fileSize: file.size,
    contentType: file.type || 'application/octet-stream'
  }

  if (file.size < fileUtils.minMultipartUploadPartSizeBytes) {
    console.log(
      'File is too small for multipart upload.  Uploading file directly'
    )
    return upload(file, options)
  }

  return new Promise((resolve, reject) => {
    try {
      const { onProgress, onComplete, onStartMultipart } = options || {}
      raiseEvent({ msg: 'Starting multipart upload' }, onStartMultipart)

      const reader = new FileReader()

      reader.onload = async () => {
        try {
          const arrayBuffer = reader.result

          // 1. Create multipart upload
          raiseEvent({ msg: 'Creating multipart upload' }, onProgress)

          const media = await createMultipartUpload(uploadRequest, options)
          console.log('createResponse', { media })

          raiseEvent({ msg: 'Created multipart upload' }, onProgress)

          // 2. Upload parts
          const parts = fileUtils.splitArrayBuffer(
            arrayBuffer,
            fileUtils.minMultipartUploadPartSizeBytes
          )

          raiseEvent(
            { msg: 'Uploading parts', parts: parts.length },
            onProgress
          )

          for (const part of parts) {
            const uploadPartRequest = {
              id: media.id,
              partNumber: parts.indexOf(part) + 1,
              bytes: part
            }

            raiseEvent(
              {
                msg: `Uploading part ${uploadPartRequest.partNumber}`,
                parts: parts.length,
                uploaded: uploadPartRequest.partNumber - 1
              },
              onProgress
            )

            await uploadPart(uploadPartRequest, options)

            raiseEvent(
              {
                msg: 'Part uploaded',
                parts: parts.length,
                uploaded: uploadPartRequest.partNumber
              },
              onProgress
            )
          }

          // 3. Complete multipart upload
          const completeResponse = await completeMultipartUpload(
            media.id,
            options
          )

          raiseEvent(
            {
              msg: 'Completed multipart upload',
              parts: parts.length,
              uploaded: parts.length
            },
            onComplete
          )
          resolve({ media: completeResponse })
        } catch (error) {
          reject(error)
        }
      }

      reader.readAsArrayBuffer(file)
    } catch (error) {
      reject(error)
    }
  })
}

const createMultipartUpload = async (request, options) => {
  const multipartPath = buildMultipartPath(options)
  return await client.__api
    .post(multipartPath, request)
    .then(response => response.data)
}

const uploadPart = (request, options) => {
  const multipartPath = buildMultipartPath(options)
  const uploadPath = `${multipartPath}/${request.id}/part/${request.partNumber}`
  const blob = new Blob([request.bytes], { type: 'application/octet-stream' })

  return client.__api
    .post(uploadPath, blob, {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    })
    .then(response => response.data)
}

const completeMultipartUpload = (id, options) => {
  const multipartPath = buildMultipartPath(options)
  const completePath = `${multipartPath}/${id}/complete`
  return client.__api.post(completePath).then(response => response.data)
}

const abortMultipartUpload = (id, options) => {
  const multipartPath = buildMultipartPath(options)
  const abortPath = `${multipartPath}/${id}/cancel`
  return client.__api.post(abortPath).then(response => response.data)
}

export default {
  dataURItoBlob,
  getBase64Url,
  delete: deleteItem,
  upload,
  uploadFormData,
  queue,
  getDownloadUrl,
  uploadMultipart,
  multipart: {
    createMultipartUpload,
    uploadPart,
    completeMultipartUpload,
    abortMultipartUpload
  }
}
