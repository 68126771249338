import configService from '@/services/config.service'

class UserProfileService {

  async fetchUserProfile(access_token) {
    const response = await fetch(`${configService.config.apiUrl}/profile`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json'
      }
    })

    if (response.ok) {
      return response.json()
    } else {
      throw new Error('Error fetching user profile')
    }
  }
}

export default new UserProfileService();
